import {
  getUserPurchases,
  getUserBalance,
  getUserReferralInfo,
  acceptUserToReferralProgram,
  sendPayoutCode,
  resendPayoutCode,
  createPayout,
} from '@/api/user';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { UserActions, UserGetters, UserMutations, UserState } from '@/types/user/store';
import { Payment, ReferralInfo } from '@/types/user/shared';
import { useNotification } from '@/composables/useNotification';
import {
  connectTelegram,
  disconnectTelegram,
  editNotificationDestination,
  editNotificationTrigger,
  getNotifications,
} from '@/api/notifications';
import { setAvatar } from '@/utils/setAvatar';

export type State = UserState;
export type Mutations = UserMutations;
export type Getters = UserGetters;
export type Actions = UserActions;

const state: () => State = () => ({
  userRequests: {
    totalSpent: 0,
    totalAvailableRequests: 0,
  },
  userBalance: {
    freePool: 0,
    mainPool: 0,
    lockedPool: 0,
    extraRequests: 0,
    subscription: {
      orderId: '',
      name: '',
      rps: 0,
      createdAt: '',
      renewAt: '',
      expireAt: '',
      total: 0,
      gracePeriod: '',
      requestBurn: '',
    },
  },
  freePackageExpireDate: '',
  userReferralInfo: {
    UserID: '',
    ReferralsRegistered: 0,
    ReferralsActive: 0,
    Payments: 0,
    EarnedBonus: 0,
    ReadyForCheckout: 0,
    ReferralLink: '',
    Checkouts: [],
  },
  checkoutHistory: [],
  apiKeys: [],
  id: '',
  avatar: '',
  checkoutId: '',
  // note: flag to check if user recently registered to show him correct balance
  isRecentlyRegistered: false,
  notifications: null,
  // note: flag for redirect back to faucet
  isFaucetLogin: false,
  faucetBackLink: ''
});

const getters: GetterTree<State, RootState> & Getters = {
  getFormattedBalance(state) {
    if (state.userBalance.subscription.total === -1) {
      return '';
    }
    let fullBalance;
    if (state.isRecentlyRegistered) {
      fullBalance = 40000;
    } else {
      fullBalance = state.userBalance.freePool + state.userBalance.mainPool;
    }
    return fullBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  },
  planName(state) {
    return state.userBalance.subscription.name;
  },
  isPlanUnlimited(state) {
    return state.userBalance.subscription.total === -1;
  },
  planPeriod(state) {
    if (state.userBalance.subscription.createdAt === '') return 0;
    const getDateFromSQLDate = (sqlDate: string) => {
      const dateParts = sqlDate.split('-');
      return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2].substring(0, 2));
    };

    const createdAt = getDateFromSQLDate(state.userBalance.subscription.createdAt);
    const expireAt = getDateFromSQLDate(state.userBalance.subscription.expireAt);

    return (
      expireAt.getMonth() -
      createdAt.getMonth() +
      12 * (expireAt.getFullYear() - createdAt.getFullYear())
    );
  },
};

const mutations: MutationTree<State> & Mutations = {
  setUserId(state, id) {
    state.id = id;
  },
  setUserAvatar(state, id) {
    state.avatar = setAvatar(id);
  },
  setUserBalance(state, balance) {
    state.userBalance = balance;
  },
  setFreePackageExpireDate(state, date) {
    state.freePackageExpireDate = date;
  },
  setIsRecentlyRegistered(state, flag) {
    state.isRecentlyRegistered = flag;
  },
  setUserReferralInfo(state, info) {
    state.userReferralInfo = info;
  },
  setCheckoutId(state, id) {
    state.checkoutId = id;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  setTelegramBindingLink(state, link) {
    state.notifications!.destination.telegram.bindingLink = link;
  },
  setIsFaucetLogin(state, value) {
    state.isFaucetLogin = value;
  },
  setFaucetBackLink(state, value) {
    state.faucetBackLink = value;
  }
};

const actions: ActionTree<State, RootState> & Actions = {
  async setUserBalance({ commit }) {
    try {
      const { data } = await getUserBalance();
      commit('setUserBalance', {
        freePool: data.free_pool,
        mainPool: data.main_pool,
        lockedPool: data.locked_pool,
        extraRequests: data.extra_requests,
        subscription: {
          orderId: data.subscription.order_id,
          name: data.subscription.name,
          rps: data.subscription.rps,
          createdAt: data.subscription.created_at,
          renewAt: data.subscription.renew_at,
          expireAt: data.subscription.expire_at,
          total: data.subscription.total,
          gracePeriod: data.subscription.grace_period,
          requestBurn: data.subscription.request_burn,
        },
      });
      if (data.subscription.name === 'Free') {
        const freePackageExpireDate = data.subscription.expire_at;
        commit('setFreePackageExpireDate', freePackageExpireDate);
      } else {
        commit('setFreePackageExpireDate', '');
      }
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_get-requests-balance'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async setUserReferralInfo({ commit }) {
    try {
      const res = await getUserReferralInfo();
      const referralInfo: ReferralInfo[] = res.data;
      commit('setUserReferralInfo', referralInfo);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_get-referral-info'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async setNotifications({ commit }) {
    try {
      const res = await getNotifications();
      commit('setNotifications', res.data);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_get-notifications'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async changeNotificationTriggerActive({ commit, state }, { triggerId, balance, recaptchaToken }) {
    try {
      const res = await editNotificationTrigger(triggerId, recaptchaToken, balance);
      commit('setNotifications', res.data);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_change-notifications-trigger'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async changeNotificationDestinationTriggerActive({ commit, state }, { destination, recaptchaToken }) {
    try {
      if (destination === 'email') {
        const res = await editNotificationDestination(recaptchaToken, { email: { enabled: !state.notifications?.destination.email.enabled } });
        commit('setNotifications', res.data);
      }

      if (destination === 'webhook') {
        const res = await editNotificationDestination(recaptchaToken, {
          webhook: {
            enabled: !state.notifications?.destination.webhook.enabled,
            url: state.notifications?.destination.webhook.url,
          },
        });
        commit('setNotifications', res.data);
      }

      if (destination === 'telegram') {
        if (state.notifications?.destination.telegram.enabled) {
          const res = await disconnectTelegram(recaptchaToken);
          commit('setNotifications', res.data);
        } else {
          const res = await connectTelegram(recaptchaToken);
          commit('setTelegramBindingLink', res.data);
          commit('setShowConnectTelegramModal', true);
        }
      }
    } catch (e) {
      const { errorData } = useNotification();
      let status;
      if (destination === 'email') {
        status = !state.notifications?.destination.email.enabled;
      }

      if (destination === 'telegram') {
        status = !state.notifications?.destination.telegram.enabled;
      }

      if (destination === 'webhook') {
        status = !state.notifications?.destination.webhook.enabled;
      }
      commit('setNotificationMessage', {
        ...errorData(`user_${status ? 'enable' : 'disable'}-notifications-${destination}-destination`),
        type: 'error',
      });
      commit('setNotificationMessageDisplay', true);
    }

  },
  async acceptReferralProgramPolicy({ commit, rootState }) {
    try {
      await acceptUserToReferralProgram();
      commit('setUserData', {
        ...rootState.profile.userData,
        is_referral_member: 1,
      });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_accept-referral-program'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async sendPayoutVerificationCode({ commit, state, rootState }, wallet_address: string) {
    try {
      const res = await sendPayoutCode(
        rootState.user.id,
        rootState.profile.userData.email,
        state.userReferralInfo.ReadyForCheckout,
        wallet_address,
      );
      commit('setCheckoutId', res.data);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_send-payout-code'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async resendPayoutVerificationCode({ commit }, checkout_id: string) {
    try {
      const res = await resendPayoutCode(checkout_id);
      commit('setCheckoutId', res.data.checkout_id);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_send-payout-code'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async createPayout({ commit, state, rootState }, code: string) {
    try {
      const res = await createPayout(state.checkoutId, code, rootState.user.id);
      commit('setShowPayoutSuccessModal', true);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('user_create-payout'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
};


const user: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default user;


