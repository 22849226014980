import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized, RouteRecordNormalized,
} from 'vue-router';
import { AccountRoutesNameType, AccountSectionsNameType, IMetaNameRoute, RoutesType } from '@/router/types';

import store from '../store';

// @ts-ignore
const LoginWithWallet = () => import('@/views/authentication/LoginWithWallet');
// @ts-ignore
const SignInWithEmail = () => import('@/views/authentication/SignInWithEmail');
// @ts-ignore
const SignUp = () => import('@/views/authentication/SignUp');
// @ts-ignore
const SignUpAffiliate = () => import('@/views/authentication/SignUpAffiliate');
// @ts-ignore
const ForgotPassword = () => import('@/views/authentication/ForgotPassword');
// @ts-ignore
const NewPassword = () => import('@/views/authentication/NewPassword');
// @ts-ignore
const ConfirmEmail = () => import('@/views/authentication/СonfirmEmail');
// @ts-ignore
const AuthLeftSide = () => import('@/components/AuthLeftSide');
// @ts-ignore
const Account = () => import('@/views/Account');
// @ts-ignore
const DedicatedNodeConfigurator = () => import('@/views/DedicatedNodeConfigurator');
// @ts-ignore
const DedicatedNodes = () => import('@/views/dashboard/DedicatedNodes');
// @ts-ignore
const General = () => import('@/views/profile/General');
// @ts-ignore
const NotificationSettings = () => import('@/views/profile/NotificationSettings')
// @ts-ignore
const MyTeam = () => import('@/views/profile/MyTeam');
// @ts-ignore
const MyBalance = () => import('@/views/profile/MyBalance');
// @ts-ignore
const PaymentHistory = () => import('@/views/profile/PaymentHistory');
// @ts-ignore
const ManageSubscriptions = () => import('@/views/profile/manageSubscriptions/ManageSubscriptions');
// @ts-ignore
const UpdateSubscription = () => import('@/views/profile/manageSubscriptions/UpdateSubscription');
// @ts-ignore
const CancelSubscription = () => import('@/views/profile/manageSubscriptions/CancelSubscription');
// @ts-ignore
const AffiliateProgram = () => import('@/views/profile/AffiliateProgram');
// @ts-ignore
const PayoutsHistory = () => import('@/views/profile/PayoutsHistory')
// @ts-ignore
const MediaAssets = () => import('@/views/profile/MediaAssets');
// @ts-ignore
const SharedNodes = () => import('@/views/dashboard/SharedNodes');
// @ts-ignore
const Dashboard = () => import('@/views/dashboard/Dashboard');
// @ts-ignore
const Checkout = () => import('@/views/Checkout');
// @ts-ignore
const Onboarding = () => import('@/views/Onboarding');
// @ts-ignore
const GitHubCallback = () => import('@/views/authentication/GitHubCallback')
// @ts-ignore
const AppChain = () => import('@/views/AppChain')

export function getAccountSectionRoute(routes: RouteRecordNormalized[], sectionName: AccountSectionsNameType) {
  const accountRoute = routes.find((item) => item.meta.name === 'account-route');
  return accountRoute?.children?.find((item) => (item.meta as IMetaNameRoute<AccountRoutesNameType>).name === sectionName);
}

const routes: RoutesType = [
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/',
    component: Account,
    meta: {
      name: 'account-route'
    },
    beforeEnter: requireAuth,
    children: [
      {
        path: '/',
        meta: {
          name: 'dashboard'
        },
        component: Dashboard,
        children: [
          {
            path: '/',
            component: SharedNodes,
            meta: {
              title: 'Shared Nodes',
              name: 'shared-nodes',
              section: 'dashboard',
            },
          },
          {
            path: '/dedicated-nodes',
            component: DedicatedNodes,
            meta: {
              title: 'Dedicated Nodes',
              name: 'dedicated-nodes',
              section: 'dashboard',
            },
          },
        ]
      },
      {
        path: '/dedicated-node-create',
        component: DedicatedNodeConfigurator,
        beforeEnter: requirePaymentPermission,
        meta: {
          title: 'Dedicated Node Configurator',
          name: 'dedicated-nodes-create',
          section: 'dedicated-nodes-create',
          isHidden: true,
        }
      },
      {
        path: '/app-chain',
        component: AppChain,
        name: 'app-chain',
        meta: {
          title: 'AppChain',
          name: 'app-chain',
          section: 'app-chain'
        }
      },
      {
        path: '/pricing',
        meta: {
          name: 'pricing'
        },
        children: [
          {
            path: '/pricing',
            name: 'plans',
            component: MyBalance,
            meta: {
              title: 'Plans',
              name: 'plans',
              section: 'pricing',
            }
          },
          {
            path: '/pricing/manage-subscriptions',
            name: 'manage-plans',
            component: ManageSubscriptions,
            beforeEnter: requirePaymentPermission,
            meta: {
              title: 'Manage Plans',
              name: 'manage-plans',
              section: 'pricing',
            },
          },
          {
            path: '/pricing/payment-history',
            name: 'payment-history',
            component: PaymentHistory,
            beforeEnter: requirePaymentPermission,
            meta: {
              title: 'Payment History',
              name: 'payment-history',
              section: 'pricing',
            }
          },
          {
            path: '/pricing/update-subscription/:updateLink',
            name: 'update-subscription',
            props: true,
            component: UpdateSubscription,
            beforeEnter: requirePaymentPermission,
            meta: {
              title: 'Update subscription',
              name: 'update-subscription',
              section: 'pricing',
              isHidden: true
            }
          },
          {
            path: '/pricing/cancel-subscription/:cancelLink',
            name: 'cancel-subscription',
            props: true,
            component: CancelSubscription,
            beforeEnter: requirePaymentPermission,
            meta: {
              title: 'Cancel Subscription',
              name: 'cancel-subscription',
              section: 'pricing',
              isHidden: true
            }
          },
        ]
      },
      {
        path: '/settings',
        meta: {
          name: 'settings'
        },
        children: [
          {
            path: '/settings',
            component: General,
            meta: {
              title: 'General Settings',
              name: 'general',
              section: 'settings',
            }
          },
          {
            path: '/settings/notifications',
            component: NotificationSettings,
            meta: {
              title: "Notifications Settings",
              name: 'notifications-settings',
              section: 'settings',
            }
          },
          {
            path: '/settings/my-team',
            component: MyTeam,
            beforeEnter: requireTeamAccount,
            meta: {
              title: 'My team',
              name: 'my-team',
              section: 'settings',
              isHidden: true
            }
          }
        ]
      },
      {
        path: '/affiliate-program',
        meta: {
          name: 'affiliate-program'
        },
        children: [
          {
            path: '/affiliate-program',
            component: AffiliateProgram,
            beforeEnter: requirePersonalAccount,
            meta: {
              title: 'Affiliate Program',
              name: 'affiliate-program-route',
              section: 'affiliate-program',
            }
          },
          {
            path: '/affiliate-program/payouts-history',
            component: PayoutsHistory,
            beforeEnter: requirePersonalAccount,
            meta: {
              title: 'Payouts History',
              name: 'payouts-history',
              section: 'affiliate-program',
            }
          },
          {
            path: '/affiliate-program/media-assets',
            component: MediaAssets,
            beforeEnter: requirePersonalAccount,
            meta: {
              title: 'Media Assets',
              name: 'media-assets',
              section: 'affiliate-program',
              isHidden: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/sign-in',
    beforeEnter: checkIfAlreadyLoggedIn,
    components: {
      default: LoginWithWallet,
      AuthLeftSide,
    },
    meta: {
      title: "Sign In"
    }
  },
  {
    components: {
      default: SignInWithEmail,
      AuthLeftSide,
    },
    children: [],
    redirect: '/sign-in',
    path: '/sign-in-email',
    meta: {
      title: "Sign In Email"
    }
  },
  {
    path: '/forgot-password',
    components: {
      default: ForgotPassword,
      AuthLeftSide,
    },
    meta: {
      title: 'Forgot Password'
    }
  },
  {
    path: '/recovery/:recoveryToken',
    props: true,
    components: {
      default: NewPassword,
      AuthLeftSide,
    },
    meta: {
      title: 'Create Password'
    }
  },
  {
    path: '/sign-up',
    beforeEnter: checkIfAlreadyLoggedIn,
    components: {
      default: SignUp,
      AuthLeftSide,
    },
    meta: {
      title: 'Sign Up'
    }
  },
  {
    path: '/sign-up-affiliate',
    beforeEnter: checkIfAlreadyLoggedIn,
    components: {
      default: SignUpAffiliate,
      AuthLeftSide,
    },
    meta: {
      title: 'Affiliate Sign Up'
    }
  },
  {
    path: '/checkout/:checkoutLink',
    props: true,
    component: Checkout,
    beforeEnter: requirePaymentPermission,
  },
  {
    path: '/git-hub/callback',
    component: GitHubCallback,
    beforeEnter: checkQueryCodeGitHub,
  },
  {
    path: '/confirm/:confirmToken',
    props: true,
    component: ConfirmEmail,
    meta: {
      title: "Email confirmation"
    }
  },
  {
    path: '/onboarding',
    component: Onboarding,
    meta: {
      title: 'Onboarding',
    },
  }
]

function checkQueryCodeGitHub(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.query.code) {
    checkLocalStorage()
    next()
  }
  else next('/sign-up');
}
function requireAuth(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (!store.state.accessToken) next('/sign-up');
  else next();
}
function checkIfAlreadyLoggedIn(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.query.team_invite) {
    // @ts-ignore
    store.commit('setTeamRef', to.query.team_invite);
  }
  if (store.state.accessToken && !to.query.team_invite) next('/');
  else next();
}
function requirePersonalAccount(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (!store.getters.isPersonalAccount) next('/settings/my-team');
  else next();
}
function requireTeamAccount(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (store.getters.isPersonalAccount) next('/');
  else next();
}
function requirePaymentPermission(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (!store.getters.hasPaymentPermission) next('/settings/my-team');
  else next();
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function checkLocalStorage() {
  if (localStorage.getItem('ref')) {
    // @ts-ignore
    store.commit('setRef', localStorage.getItem('ref'));
    localStorage.removeItem('ref')
  }
  if (localStorage.getItem('utm_source')) {
    // @ts-ignore
    store.commit('utm_source', JSON.parse(localStorage.getItem('utm_source') as string));
    localStorage.removeItem('utm_source')
  }
  if (localStorage.getItem('utm_medium')) {
    // @ts-ignore
    store.commit('utm_medium', JSON.parse(localStorage.getItem('utm_medium') as string));
    localStorage.removeItem('utm_medium')
  }
  if (localStorage.getItem('utm_medium')) {
    // @ts-ignore
    store.commit('utm_campaign', JSON.parse(localStorage.getItem('utm_campaign') as string));
    localStorage.removeItem('utm_campaign')
  }
  if (localStorage.getItem('utm_medium')) {
    // @ts-ignore
    store.commit('utm_content', JSON.parse(localStorage.getItem('utm_content') as string));
    localStorage.removeItem('utm_content')
  }
  if (localStorage.getItem('utm_medium')) {
    // @ts-ignore
    store.commit('utm_term', JSON.parse(localStorage.getItem('utm_term') as string));
    localStorage.removeItem('utm_term')
  }
}

router.beforeEach((toRoute, fromRoute, next) => {
  if (toRoute.query['login-marketplace']) {
    // @ts-ignore
    store.commit('setIsFaucetLogin', toRoute.query['login-marketplace']);
    // @ts-ignore
    store.commit('setFaucetBackLink', toRoute.query['back-link']);
  }

  if (toRoute.query.ref) {
    // @ts-ignore
    store.commit('setRef', toRoute.query.ref);
    localStorage.setItem('ref', toRoute.query.ref as string)
  }
  if (toRoute.query.openConfigurator) {
    // @ts-ignore
    store.commit('setIsNeedToShowDedicatedNodeConfigurator', true);
  }
  if (toRoute.query.utm_source) {
    // @ts-ignore
    store.commit('setUtmVariables', { utmName: 'source', value: toRoute.query.utm_source });
    localStorage.setItem('utm_source', JSON.stringify({ utmName: 'source', value: toRoute.query.utm_source }))
  }
  if (toRoute.query.utm_medium) {
    // @ts-ignore
    store.commit('setUtmVariables', { utmName: 'medium', value: toRoute.query.utm_medium });
    localStorage.setItem('utm_medium', JSON.stringify({ utmName: 'medium', value: toRoute.query.utm_medium }))
  }
  if (toRoute.query.utm_campaign) {
    // @ts-ignore
    store.commit('setUtmVariables', { utmName: 'campaign', value: toRoute.query.utm_campaign });
    localStorage.setItem('utm_campaign', JSON.stringify({ utmName: 'campaign', value: toRoute.query.utm_campaign }))
    if (store.state.accessToken && toRoute.query.utm_campaign === 'referralprogram') next('/profile/affiliate-program');
  }
  if (toRoute.query.utm_content) {
    // @ts-ignore
    store.commit('setUtmVariables', { utmName: 'content', value: toRoute.query.utm_content });
    localStorage.setItem('utm_content ', JSON.stringify({ utmName: 'content', value: toRoute.query.utm_content }))

  }
  if (toRoute.query.utm_term) {
    // @ts-ignore
    store.commit('setUtmVariables', { utmName: 'term', value: toRoute.query.utm_term });
    localStorage.setItem('utm_term ', JSON.stringify({ utmName: 'term', value: toRoute.query.utm_term }))
  }
  window.document.title =
    toRoute.meta && toRoute.meta.title ? `${toRoute.meta.title} | GetBlock.io` : 'Account | GetBlock.io';
  window.scrollTo(0, 0);
  next();
});

export default router;