import { httpClientV6 } from '@/shared/api/httpClient';
import {
  ICreatedSubscriptionOrder,
  IDedicatedSubscriptionCreateBody,
  ISharedSubscriptionCreateBody,
} from '@/entities/subscriptionCreate/types';
import store from '@/store';
import { AxiosResponse } from 'axios';

const subscriptionCreate = (subscriptionCreateBody: ISharedSubscriptionCreateBody | IDedicatedSubscriptionCreateBody) =>
  httpClientV6.post(
    'subscription/create',
    subscriptionCreateBody,
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  ) as Promise<AxiosResponse<ICreatedSubscriptionOrder>>;

export { subscriptionCreate }