import { httpClientV6 } from '@/shared/api/httpClient';
import store from '@/store';
import { AxiosResponse } from 'axios';
import { IDedicatedSubscription, ISharedSubscription } from '@/entities/subscriptions/types';

const fetchSubscriptions = () => httpClientV6.get('/subscription/', {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  },
) as Promise<AxiosResponse<(ISharedSubscription | IDedicatedSubscription)[]>>

export { fetchSubscriptions }