import { PackagesActions, PackagesGetters, PackagesMutations, PackagesState } from '@/types/packages/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { useNotification } from '@/composables/useNotification';
import { fetchPlans } from '@/entities/sharedPlans/api';
import { ISharedPlans } from '@/entities/sharedPlans/types';
import { StartPlanProductId, UnlimitedPlanProductId } from '@/shared/products/types';
import { IRequestPackages } from '@/entities/requestPackages/types';
import { fetchPacks } from '@/entities/requestPackages/api';

export type State = PackagesState;
export type Mutations = PackagesMutations;
export type Getters = PackagesGetters;
export type Actions = PackagesActions;

const state: () => State = () => ({
  startPlans: [],
  unlimitedPlans: [],
  packs: [],
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setStartPlans(state, payload) {
    state.startPlans = payload
  },
  setUnlimitedPlans(state, payload) {
    state.unlimitedPlans = payload
  },
  setPacks(state, payload) {
    state.packs = payload
  }
};

const actions: ActionTree<State, RootState> & Actions = {
  async requestPlans({commit}) {
    try {
      const { data } = await fetchPlans();
      const startPlans = Object.values(data).filter((item) => item._id.includes('start'))
      const unlimitedPlans = Object.values(data).filter((item) => item._id.includes('unlim'))
      commit('setStartPlans', startPlans.sort((a,b) => a.requests - b.requests))
      commit('setUnlimitedPlans', unlimitedPlans)
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('general_get-increase-pricing'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async requestPacks({commit}) {
    try {
      const { data } = await fetchPacks()
      commit('setPacks', Object.values(data) as IRequestPackages[])
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('general_get-increase-pricing'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  }
};

const packages: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default packages;
